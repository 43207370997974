import React from 'react';
import hero from '../../../img/hero-1.png'
import maszidPlan from '../../../img/maszid-plan.jpg'
import maszidNow from '../../../img/maszid-now.jpg'
import madrashaPlan from '../../../img/madrasha-plan.jpg'
import madrashaNow from '../../../img/madrasha-now.jpg'

const Home = () => {
  return (
    <div className='mx-auto'>
      <div className="hero min-h-96 rounded-box lg:mt-10" style={{ backgroundImage: `url(${hero})` }}>
        <div className="hero"></div>
        <div className="hero-content text-center bg-base-100 opacity-80 rounded-box">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold text-primary">মাদ্রাসাতুল মানারাহ ও মসজিদ কমপ্লেক্স</h1>
            <p className="mb-5">সহীহ সুন্নাহ ভিত্তিক আদর্শ বিদ‌্যাপিঠ</p>
            <button className="btn font-bold text-lg btn-primary" onClick={() => document.getElementById('my_modal_1').showModal()}>আপনার সন্তানকে ভর্তি করুন</button>
            <dialog id="my_modal_1" className="modal">
              <div className="modal-box">
                <h3 className="font-bold text-lg text-center">সঠিক তথ‌্য দিয়ে ঘরগুলো পূরণ করুন!</h3>

                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdMzMVzvpBjlwgVM6jyjocqn4QWD9-LTVM4dNYk1Nnp96RsPQ/viewform?embedded=true" width="100%" height="600px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                <div className="modal-action">
                  <form method="dialog">
                    <button className="btn btn-error">Close</button>
                  </form>
                </div>
              </div>
            </dialog>
          </div>
        </div>
      </div>


      <div className='grid grid-cols-3'>
        <div>
          <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img src="https://www.badrihighschool.edu.pk/wp-content/uploads/2021/07/quran1.jpg" alt="Shoes" /></figure>
            <div className="card-body">
              <h2 className="card-title">হিফজুল কুরআন!</h2>
            </div>
          </div>
        </div>
        <div>
          <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img src="https://www.badrihighschool.edu.pk/wp-content/uploads/2021/07/english.jpg" alt="Shoes" /></figure>
            <div className="card-body">
              <h2 className="card-title">কিতাব বিভাগ!</h2>
            </div>
          </div>
        </div>
        <div>
          <div className="card w-96 bg-base-100 shadow-xl">
            <figure><img src="https://jameashamimabad.com/wp-content/uploads/2016/03/n5-1.gif" alt="Shoes" /></figure>
            <div className="card-body">
              <h2 className="card-title">ইংরেজি শিক্ষা</h2>
            </div>
          </div>
        </div>
      </div>




      <div className="mt-20 mb-20 grid grid-cols-1 lg:md:grid-cols-2 gap-4">
        <div>
          <h1 className='text-center text-3xl font-bold mb-3'>মসজিদ কমপ্লেক্স</h1>
          <div className="diff aspect-[16/9]">
            <div className="diff-item-1">
              <div className="grid place-content-center"><img src={maszidNow}></img></div>
            </div>
            <div className="diff-item-2">
              <div className="grid place-content-center"> <img src={maszidPlan}></img> </div>
            </div>
            <div className="diff-resizer"></div>
          </div>
        </div>

        <div>
          <h1 className='text-center text-3xl font-bold mb-3'>মাদ্রাসা ভবন</h1>
          <div className="diff aspect-[16/9]">
            <div className="diff-item-1">
              <div className="grid place-content-center"><img src={madrashaNow}></img></div>
            </div>
            <div className="diff-item-2">
              <div className="grid place-content-center"> <img src={madrashaPlan}></img> </div>
            </div>
            <div className="diff-resizer"></div>
          </div>
        </div>
      </div>











      <div className='mx-auto w-100 lg:md:w-80 mb-20 mt-10'>
        <div className="stats">

          <div className="stat">
            <div className="stat-title">ডিপার্টমেন্ট</div>
            <div className="stat-value">৪টি</div>
            <div className="stat-desc">+উন্নতি চলছে</div>
          </div>

          <div className="stat">
            <div className="stat-title">শিক্ষার্থী</div>
            <div className="stat-value">৩৫০</div>
            <div className="stat-desc">ভর্তি চলমান</div>
          </div>

          <div className="stat">
            <div className="stat-title">শিক্ষক</div>
            <div className="stat-value">১১+</div>
            <div className="stat-desc">নিয়োগ চলমান</div>
          </div>

        </div>
      </div>








      <div className='w-96 lg:md:w-2/5 mx-auto'>
        <h2 className='text-center text-2xl font-bold mb-5'>সাধারণ জিজ্ঞাসা</h2>
        <div className="mb-5 collapse collapse-plus bg-base-200">
          <input type="radio" name="my-accordion-2" checked="checked" />
          <div className="collapse-title text-xl font-medium">
            আপনার সন্তানকে কেন আমাদের মাদ্রাসায় দিবেন?
          </div>
          <div className="collapse-content">
            <p>আমরা অত‌্যন্ত যত্নসহকারে প্রশিক্ষিত শিক্ষক মণ্ডলী দ্বারা পাঠদান করাই।</p>
          </div>
        </div>
        <div className="mb-5 collapse collapse-plus bg-base-200">
          <input type="radio" name="my-accordion-2" />
          <div className="collapse-title text-xl font-medium">
            আমরা কি কি পড়াই?
          </div>
          <div className="collapse-content">
            <p>আরবি, বাংলা, ইংরেজি সহ ইসলামে মৌলিক জ্ঞান এবং হিফজুল কুরআন ইত‌্যাদি</p>
          </div>
        </div>
        <div className="mb-5 collapse collapse-plus bg-base-200">
          <input type="radio" name="my-accordion-2" />
          <div className="collapse-title text-xl font-medium">
            আপনাদের মাদ্রাসার খরচ কত?
          </div>
          <div className="collapse-content">
            <p>খুবই কম খরচ। খরচের বিবরণ</p>
          </div>
        </div>
      </div>





    </div>
  );
};

export default Home;