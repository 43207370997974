import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../../img/Logo.svg'

const Navbar = () => {

    const menuItems = <React.Fragment>
        <li><Link to="/">হোম</Link></li>
        <li><Link to="/about">আমাদের সম্পর্কে</Link></li>
        <li><Link to="/login">লগিন</Link></li>

    </React.Fragment>
    return (
        <div className="sticky top-0 navbar bg-base-100 z-50 shadow-2xl">
            <div className="navbar-start">
                <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </div>
                    <ul tabIndex={0} className="menu menu-sm font-bold text-lg dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                        {menuItems}
                    </ul>
                </div>
                <Link to="/"><img className='btn-active btn-link w-full' src={logo}></img></Link>
            </div>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1 font-bold text-lg">
                    {menuItems}
                </ul>
            </div>
            <div className="navbar-end">
                <button className="btn btn-primary {red} text-white text-lg rounded-box" onClick={() => document.getElementById('my_modal_1').showModal()}>Admission</button>
                <dialog id="my_modal_1" className="modal">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg text-center">সঠিক তথ‌্য দিয়ে ঘরগুলো পূরণ করুন!</h3>
                       
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdMzMVzvpBjlwgVM6jyjocqn4QWD9-LTVM4dNYk1Nnp96RsPQ/viewform?embedded=true" width="100%" height="600px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                        <div className="modal-action">
                            <form method="dialog">
                                <button className="btn btn-primary">Close</button>
                            </form>
                        </div>
                    </div>
                </dialog>


            </div>
        </div>
    );
};

export default Navbar;