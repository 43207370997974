import React from 'react';

const Jobs = () => {
    return (
        <div>

            <div className="mockup-window border bg-base-300 mb-20 mt-20 mx-auto">
                <div className="flex justify-center px-4 py-16 bg-base-200"><h2 className='text-2xl'>দুঃখিত! এই পাতাটির উন্নয়ন কাজ চলছে..</h2></div>
            </div>



        </div>
    );
};

export default Jobs;